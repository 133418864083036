import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Subsection from "./Subsection"
import SubsectionTitle from "./SubsectionTitle"

const Outside = () => {
    return (
    <Subsection id="outside">
        <SubsectionTitle>À l'extérieur</SubsectionTitle>
        <p className="mb-4">Cour, terrasse, salon de jardin, jardin, barbecue, parasol.</p>
        <div className="lg:grid lg:gap-x-4 lg:grid-cols-2">
                <div className="mb-4">
                    <StaticImage className="rounded-lg shadow" src="../images/plane-trees.jpg" alt="Picture of the plane trees" />
                </div>
                <div className="mb-4">
                    <StaticImage className="rounded-lg shadow" src="../images/olive-orchard.jpg" alt="Picture of the olive orchard" />
                </div>
            </div>
    </Subsection>
    )
}

export default Outside
