import React from "react"
import Subsection from "./Subsection"

const Introduction = () => {
    return(
    <Subsection id="intro">
        <p>
            Le mas des Platanes, exploitation agricole, 
            accueille ses hôtes dans deux gîtes 
            aménagés dans l'ancienne bergerie.<br/>
            Authenticité et calme aux portes d'Arles, 
            de la Camargue et des Alpilles.
        </p>
    </Subsection>
    )
}

export default Introduction
