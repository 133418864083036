import React from "react"

const SubsectionTitle = (props) => {
    return (
        <h3 
        className="mb-4 font-title text-2xl text-center lg:text-left">
            {props.children}
        </h3>
    )
}

export default SubsectionTitle
