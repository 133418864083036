import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Subsection from "./Subsection"
import SubsectionTitle from "./SubsectionTitle"

const Kitchen = () => {
    return (
        <Subsection id="kitchen">
            <SubsectionTitle></SubsectionTitle>
            <div className="lg:grid lg:gap-x-4 lg:grid-cols-2">
                <div className="mb-4">
                    <StaticImage className="rounded-lg shadow" 
                    src="../images/outdoor-kitchen.jpg" 
                    alt="Picture of the outdoor kitchen from the front" />
                </div>
                <div className="mb-4">
                    <StaticImage className="rounded-lg shadow" 
                    src="../images/sheepfold.jpg" 
                    alt="Picture of the fron of the sheepfold" />
                </div>
            </div>
        </Subsection>
    )
}

export default Kitchen
