import React from "react"
import Subsection from "./Subsection"
import SubsectionTitle from "./SubsectionTitle"

const Leisure = () => {
    return (
        <Subsection id="leisure">
            <SubsectionTitle></SubsectionTitle>
            <p>
                Loisirs nombreux.
            </p>
        </Subsection>
    )
}

export default Leisure
