import React from "react"

const SectionTitle = (props) => {
    return (
        <h2
        className="my-8 uppercase font-bold font-title text-3xl text-center lg:text-left lg:text-5xl">
            {props.children}
        </h2>
    )
}

export default SectionTitle
