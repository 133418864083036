import React from "react"

const Title = () => {
    return (
    <div className="my-6 py-4">
        <h1 className="font-title font-bold text-2xl md:text-4xl lg:text-6xl text-center">
            Gîtes du Mas des Platanes
        </h1>
        
        <h2 className="text-primary font-title text-xl md:text-3xl lg:text-5xl text-center">
            Gîtes et roulotte
        </h2>
    </div>
    )
}

export default Title
