import React from "react"
import SectionTitle from "./SectionTitle"

const CallToAction = () => {
    return (
        <div id="CTA">
            <SectionTitle>Réservations</SectionTitle>
            <div className="mx-8 lg:mx-0 mb-8 md:mb-16 font-body">
                <p>Prix à la demande.</p>
                <p>N'hésitez pas à appeler ou à envoyer un e-mail à votre hôte!</p>
            </div>
            <div className="mb-16 text-center lg:text-left">
                <a 
                className="text-red-50 mb-8 md:mx-0 mx-8 px-8 py-4 md:mr-16 block md:inline bg-primary rounded-full shadow hover:bg-red-300" 
                href="tel:+33627640575">
                    Appeler
                </a>

                <a 
                className="text-gray-50 px-8 py-4 bg-secondary hover:bg-gray-400 rounded-full shadow" 
                href="mailto:michotey@yahoo.fr">
                    Envoyer un e-mail
                </a>
            </div>
        </div>
    )
}

export default CallToAction
