import React from "react"

const Subsection = (props) => {
    // This component is used to sahare props among Subsections
    return (
        <div id={props.id} 
        className={'font-body my-8 mx-8 lg:mx-0 ' + props.className}>
            {props.children}
        </div>
    )
}

export default Subsection
