import React from "react"
import Subsection from "./Subsection"
import SubsectionTitle from "./SubsectionTitle"

const Facilities = () => {
    return (
        <Subsection id="facilities">
            <SubsectionTitle>Equipements</SubsectionTitle>
            <p>
                Équipement tout confort avec Wi-Fi et parking privatif.<br/>
                Linge de maison fourni : draps et serviettes de bain.<br/>
                Cuisine équipée.
            </p>
        </Subsection>
    )
}

export default Facilities
