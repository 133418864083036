import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Subsection from "./Subsection"
import SubsectionTitle from "./SubsectionTitle"

const Area = () => {
    return (
        <Subsection id="area">
            <SubsectionTitle></SubsectionTitle>
            <div className="mb-4">
                <b>2 gîtes pour 4 personnes, 1 roulotte pour 2 personnes avec lit king size.</b>
            </div>
            <div className="lg:grid lg:gap-x-4 lg:grid-cols-2">
                <div className="mb-4">
                    <StaticImage className="rounded-lg shadow" 
                    src="../images/caravan.jpg" 
                    alt="The interior of the Mas des Platanes' caravan" />
                </div>
                <div className="mb-4">
                    <StaticImage className="rounded-lg shadow" 
                    src="../images/caravan-inside.jpg" 
                    alt="Picture of the inside of the caravan from the bed" />
                </div>
            </div>
        </Subsection>
    )
}

export default Area
