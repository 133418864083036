import React from "react"

import Title from "../components/Title"
import HeroImage from "../components/HeroImage"
import Contact from "../components/Contact"
import HSeparator from "../components/HSeparator"
import Description from "../components/Description"
import CallToAction from "../components/CallToAction"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return(
    <main>
      <Helmet>
        <title>Mas des Platanes</title>
        <meta name="description" content="Maison d'hôtes sur Arles située en Provence entre Alpilles, Camargue et Crau." />
        <link rel="canonical" href="https://masdesplatanes.fr" />
      </Helmet>
      <div className="mb-8 mx-4 md:mx-8 lg:mx-16 xl:mx-32 2xl:mx-64">
        <Title />
        <HeroImage />
        <Contact />
        <HSeparator />
        <Description />
        <HSeparator />
        <CallToAction />
      </div>
      <Footer />
    </main>
  )
}

export default IndexPage
