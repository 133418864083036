import React from "react"

const Footer = () => {
    return (
        <div id="footer" className="bg-ternary py-16">
            <div className="mx-8 lg:mx-16 xl:mx-32 2xl:mx-64 text-white">
                <div className="uppercase underline mb-3">Adresse</div>
                <address className="not-italic mb-2">
                    Mas des Platanes,<br/>
                    88 route de la Coste-Basse<br/>
                    13200 ARLES
                </address>
            </div>
        </div>
    )
}

export default Footer
