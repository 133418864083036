import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import SectionTitle from "./SectionTitle"
import Introduction from "./Introduction"
import Summary from "./Summary"
import Area from "./Area"
import Facilities from "./Facilities"
import Kitchen from "./Kitchen"
import Outside from "./Outside"
import Leisure from "./Leisure"
import OtherDetails from "./OtherDetails"


const Description = () => {
    return (
    <div id="description">
        <SectionTitle>Description</SectionTitle>
        <Introduction />
        <StaticImage className="rounded-lg shadow" 
        src="../images/facade.jpg" 
        alt="The Mas des Platanes' facade " />

        <div id="details">
            <Summary />
            <Area />
            <Facilities />
            <Kitchen />
            <Outside />
            <Leisure />
            <OtherDetails />
        </div>
    </div>
    )
}

export default Description
