import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const HeroImage
 = () => {
    return(
        <StaticImage className="rounded-lg shadow" 
        src="../images/sky.jpg" 
        alt="Mas des Platanes shot from the sky" />
    )
}

export default HeroImage
