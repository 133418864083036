import React from "react"
import SectionTitle from "./SectionTitle"

const Contact = () => {
    return (
        <div id="contact" className="my-8 text-center lg:text-left">
            <SectionTitle>Contact</SectionTitle>
            <address className="font-title not-italic text-xl">
                <b className="text-2xl">Mas des Platanes</b><br/>
                88 route de la Coste-Basse
                13200 ARLES<br/>
                <div>
                    <a 
                    className="md:mr-8 
                    underline text-primary block md:inline" 
                    href="mailto:michotey@yahoo.fr">
                        michotey@yahoo.fr
                    </a>
                    
                    <a className="underline text-primary" 
                    href="tel:+33627640575">
                        +33 (0)6 27 64 05 75
                    </a>
                </div>
            </address>
        </div>
    )
}

export default Contact
