import React from "react"
import Subsection from "./Subsection"
import SubsectionTitle from "./SubsectionTitle"

const OtherDetails = () => {
    return (
        <Subsection id="other-details">
            <SubsectionTitle>Details</SubsectionTitle>
            <b>
                <ul className="list-disc ml-4">
                    <li>Les animaux autorisés</li>
                    <li>Animaux vivants sur place : Ânes et chevaux</li>
                    <li>Location non-fumeur</li>
                </ul>
            </b>
        </Subsection>
    )
}

export default OtherDetails
