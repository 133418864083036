import React from "react"
import Subsection from "./Subsection"

const Summary = () => {
    return (
        <Subsection id="summary">
            <div id="catchphrase">
                <p className="uppercase font-bold text-center lg:text-left mb-2">Mas en pierre provençal typique</p>
            </div>
            <p>
                Logement de caractère dans mas du 18e siècle<br/>
                Mas au milieu de prairies et d'oliveraies. 
                Idéalement situé entre Alpilles, Camargue et Crau, 
                à 5 minutes des commodités urbaines et du centre historique.
            </p>
        </Subsection>
    )
}

export default Summary
